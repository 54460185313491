import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ReactComponent as CheckboxIcon } from "@patentedai/web/images/checkbox.svg";
import { ReactComponent as CheckboxCheckedIcon } from "@patentedai/web/images/checkboxChecked.svg";
import { ClaimElementData } from "@patentedai/common/entities/Patent";
import { styled } from "@mui/material/styles";

interface ClaimsRowProps {
  claim?: ClaimElementData;
  isClaimSelected: (claimId: number) => boolean;
  handleClaimSelect?: (claimId: number) => void;
}

const ClaimsRow = ({ claim, isClaimSelected, handleClaimSelect }: ClaimsRowProps) => {
  const getClaimText = (claim: ClaimElementData) => {
    return (
      <>
        <div>{claim.element}</div>
        {claim.subElements.map((subElement, index) => (
          <div className="sub-element" key={index}>
            <span className="sub-element-id">{subElement.id}</span>
            {` ${subElement.subElement}`}
          </div>
        ))}
      </>
    );
  };

  if(!claim) return null;
  return (
    <>
      <TableRow role="checkbox" tabIndex={-1} key={claim.id}>
        {handleClaimSelect && (
          <StyledTableCell id="checkmark" key="checkmark" align="left" rowSpan={1}>
            <Checkbox
              checked={isClaimSelected(claim.id)}
              onChange={() => handleClaimSelect(claim.id)}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
            />
          </StyledTableCell>
        )}
        <StyledTableCell
          id="claimNumber"
          className={`${isClaimSelected(claim.id) ? "selected" : "not-selected"}`}
          key="claimNumber"
          align="left"
          rowSpan={1}
        >
          <span>{claim.id}</span>
        </StyledTableCell>
        <StyledTableCell id="claimContent" className={`${isClaimSelected(claim.id) ? "selected" : "not-selected"}`} key="claimContent" align="left">
          {getClaimText(claim)}
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const StyledTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background-color: #fbfaff;
    border: 1px solid #e7e7e7;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    color: #404246;
    height: 30px;
    padding: 0;
  }

  &.${tableCellClasses.body} {
    font-size: 14px;
    vertical-align: text-top;
  }
`;

export default ClaimsRow;

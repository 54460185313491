import { WaitlistStatus } from "../entities/Waitlist";
import { SuccessResponse } from "./Common";

enum AuthorizationProvider {
    Google = "GoogleOAuth",
    Microsoft = "MicrosoftOAuth"
}

interface GetAuthorizationUrlParameters {
    provider: AuthorizationProvider;
    state?: string;
}
interface GetAuthorizationUrlResponse extends SuccessResponse {
    url: string;
}

interface GetProfileParameters {
    code: string;
}
interface GetProfileResponse extends SuccessResponse {
    token: string;
    profile: {
        provider: string;
        email: string;
        firstName: string;
        lastName: string;
        userExists: boolean;
        waitlistStatus?: WaitlistStatus;
    }
}

interface UpdateProfileParameters {
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
}

export {
    AuthorizationProvider
};

export type {
    GetAuthorizationUrlParameters,
    GetAuthorizationUrlResponse,
    GetProfileParameters,
    GetProfileResponse,
    UpdateProfileParameters
};
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@patentedai/web/components/core/Button";
import Stack from "@patentedai/web/components/core/Stack";
import FormControl from "@patentedai/web/components/core/FormControl";
import { UserStatus } from "@patentedai/common/entities/User";
import { UserRole } from "@patentedai/common/entities/User";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { FormHelperText, InputLabel, FormControl as MaterialFormControl } from "@mui/material";
import PhoneInput from "@patentedai/web/components/core/PhoneInput/PhoneInput";
import { User } from "../../entities/User";
import { createDefaultWorkspace, joinToWorkspace, updateProfile } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { workspaceNameSanitizer } from "@patentedai/common/helpers/sanitizerHelper";
import * as Sentry from "@sentry/react";

interface PersonalInfoVM {
    firstName: string;
    lastName: string;
    organization: string;
    phoneNumber: string;
}

const PersonalInfo = () => {
    const location = useLocation();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfoVM>({
        firstName: location.state?.firstName || "",
        lastName: location.state?.lastName || "",
        organization: location.state?.orgName || "",
        phoneNumber: "",
    });
    const [personalInfoError, setPersonalInfoError] = useState<PersonalInfoVM>({
        firstName: "",
        lastName: "",
        organization: "",
        phoneNumber: "",
    });
    const navigate = useNavigate();
    const { setUser, setWorkspace } = useAuth();
    const orgId = location.state.orgId;
    const isOrgInvite = !!location.state.orgName && !!orgId;


    const handleChange = (e: any) => {
        const { name } = e.target;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const newData = { ...personalInfo, [name]: value };
        setPersonalInfo(newData);
        validateOnChange && validate(newData);
    };

    const validate = (data: PersonalInfoVM) => {
        const errors: PersonalInfoVM = {
            firstName: "",
            lastName: "",
            organization: "",
            phoneNumber: "",
        };
        const { firstName, lastName, organization, phoneNumber } = data;

        let isValid = true;
        if (!firstName.trim()) {
            errors.firstName = "First name is required";
            isValid = false;
        }

        if (!lastName.trim()) {
            errors.lastName = "Last name is required";
            isValid = false;
        }

        if (!organization.trim()) {
            errors.organization = "Organization is required";
            isValid = false;
        }

        if (!phoneNumber.trim()) {
            errors.phoneNumber = "Phone number is required";
            isValid = false;
        }
        else if (!isValidPhoneNumber) {
            errors.phoneNumber = "Phone number is invalid";
            isValid = false;
        }

        setPersonalInfoError(errors);
        return isValid;
    };

    const handleConfirm = async () => {
        setValidateOnChange(true);
        if (!validate(personalInfo))
            return;

        try {
            setIsSaving(true);

            const user = await User.create(location.state?.uid || "",
                {
                    firstName: personalInfo.firstName,
                    lastName: personalInfo.lastName,
                    email: location.state?.email || "",
                    phone: personalInfo.phoneNumber,
                    status: UserStatus.Active,
                    role: UserRole.User,
                    createdAt: new Date(),
                });
            await updateProfile({
                uid: location.state?.uid,
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
                email: location.state?.email
            });

            if (isOrgInvite) {
                const createDefaultWorkspaceResponse = await joinToWorkspace({ workspaceId: orgId });
                if (createDefaultWorkspaceResponse.status !== ResponseStatus.Success)
                    throw new Error("Error joining to workspace");

                user.defaultWorkspaceId = createDefaultWorkspaceResponse.workspace.id;
                setUser(user);
                setWorkspace(createDefaultWorkspaceResponse.workspace);
            }
            else {
                const createDefaultWorkspaceResponse = await createDefaultWorkspace({ name: workspaceNameSanitizer(personalInfo.organization) });
                if (createDefaultWorkspaceResponse.status !== ResponseStatus.Success)
                    throw new Error("Error creating default workspace");

                user.defaultWorkspaceId = createDefaultWorkspaceResponse.workspace.id;
                setUser(user);
                setWorkspace(createDefaultWorkspaceResponse.workspace);
            }

            navigate("/signin/finalstep", { replace: true });

        } catch (error: any) {
            console.log({ error });
            Sentry.captureException(error);
        }
        finally {
            setIsSaving(false);
        }
    };

    const onChangePhoneNumber = (value: string) => {
        const newData = { ...personalInfo, phoneNumber: value || "" };
        setPersonalInfo(newData);
        validateOnChange && validate(newData);
    };

    const onChangeIsValidPhoneNumber = (value: boolean) => {
        setIsValidPhoneNumber(value);
        validateOnChange && validate(personalInfo);
    };

    return (
        <div className="content personal-content">

            <h1 className="title-header">One more thing...</h1>
            <h3 className="sub-title-header">Confirm your account information</h3>
            <Stack spacing={"10px"} className="custom-stack">
                <FormControl
                    id="firstName"
                    name="firstName"
                    label="First Name/s*"
                    size="small"
                    placeholder="Please add your first & middle names"
                    value={personalInfo.firstName}
                    helperText={personalInfoError.firstName}
                    error={!!personalInfoError.firstName}
                    onChange={handleChange}
                />
                <FormControl
                    id="lastName"
                    name="lastName"
                    label="Last Name/s*"
                    size="small"
                    placeholder="Please add your last name/s"
                    value={personalInfo.lastName}
                    helperText={personalInfoError.lastName}
                    error={!!personalInfoError.lastName}
                    onChange={handleChange}
                />
                <FormControl
                    id="organization"
                    name="organization"
                    label="Organization*"
                    size="small"
                    placeholder="Please add your Organization name"
                    value={personalInfo.organization}
                    helperText={personalInfoError.organization}
                    error={!!personalInfoError.organization}
                    disabled={isOrgInvite}
                    onChange={handleChange}
                />
                <Stack spacing={"8px"}>
                    <InputLabel
                        htmlFor={"phoneNumber"}
                        style={{ fontSize: "12px" }}>
                        Phone Number
                    </InputLabel>
                    <MaterialFormControl
                        fullWidth
                        size="small"
                        error={false}>
                        <PhoneInput
                            id={"phoneNumber"}
                            name={"phoneNumber"}
                            useLocalFormat
                            placeholder="(+1) 000 - 0000"
                            value={personalInfo.phoneNumber}
                            onChange={onChangePhoneNumber}
                            onValidate={onChangeIsValidPhoneNumber}
                            error={!!personalInfoError.phoneNumber}
                        />

                    </MaterialFormControl>
                    {personalInfoError.phoneNumber &&
                        <FormHelperText
                            error>
                            {personalInfoError.phoneNumber}
                        </FormHelperText>}
                </Stack>
            </Stack>
            <Button
                className="button-action"
                variant="contained"
                fullWidth
                color="primary"
                textTransform="none"
                showSpinner={isSaving}
                onClick={handleConfirm}
            >
                Continue & Confirm
            </Button>

        </div>
    );
};

export default PersonalInfo;
import { Checkbox } from "@mui/material";
import { ReactComponent as CheckboxIcon } from "@patentedai/web/images/checkbox.svg";
import { ReactComponent as CheckboxCheckedIcon } from "@patentedai/web/images/checkboxChecked.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ClaimsRow from "./ClaimsRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { ClaimElementData } from "@patentedai/common/entities/Patent";

const StyledTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background-color: #fbfaff;
    border: 1px solid #e7e7e7;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    color: #404246;
    height: 30px;
    padding: 0;
  }

  &.${tableCellClasses.body} {
    font-size: 14px;
    vertical-align: text-top;
  }
`;

interface ClaimsTableProps {
  claims?: ClaimElementData[];
  isClaimSelected: (claimId: number) => boolean;
  handleClaimSelect?: ((claimId: number) => void) | undefined;
  isAllClaimsSelected?: boolean;
  handleSelectAllClaims?: () => void;
}

const ClaimsTable = ({ claims, isClaimSelected, handleClaimSelect, isAllClaimsSelected, handleSelectAllClaims }: ClaimsTableProps) => {
  if (!claims) return null;
  return (
    <TableContainer className="claims-table" sx={{ maxHeight: "calc(100% - 120px)" }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {handleClaimSelect && handleSelectAllClaims && (
              <StyledTableCell key="checkmark" align="left" style={{ borderRadius: "5px 0px 0px 5px" }}>
                <Checkbox
                  checked={isAllClaimsSelected}
                  onChange={handleSelectAllClaims}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              </StyledTableCell>
            )}
            <StyledTableCell align="left">Claim</StyledTableCell>
            <StyledTableCell align="left">Claim Content</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {claims.map((claim) => {
            return <ClaimsRow key={claim?.id} claim={claim} isClaimSelected={isClaimSelected} handleClaimSelect={handleClaimSelect} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClaimsTable;

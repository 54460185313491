import { DateTime } from "luxon";
import ClaimOverlapTable from "searchs/components/ClaimOverlapTable";
import { checkSubstringInEither, sortProductsByDateAndName, extractUMLCode, filterText } from "utils/searchDetailsUtils";
import UseCaseCard from "searchs/components/UseCaseCard";
import { useEffect, useRef, useState } from "react";

import { Backdrop } from "@mui/material";
import PriorArtDetailMenu from "../menu/PriorArtDetailMenu";
import { Loading } from "@patentedai/web/components/core/Loading";
import { createMatter, getSearchProductRelatedPatents } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// @ts-ignore
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { IndustriesTag } from "@patentedai/web/images/IndustriesTag";
import { ReactComponent as PatentIcon } from "@patentedai/web/images/patentIcon.svg";
import { ReactComponent as DottedDoc } from "@patentedai/web/images/dottedDoc.svg";
import Button from "@patentedai/web/components/core/Button";
import { useSearch } from "contexts/SearchContext";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import AddToMatterModal from "../modal/AddToMatterModal";
import { useNavigate } from "react-router-dom";
import UserHeader from "./UserHeader";
import plantumlEncoder from "plantuml-encoder";
import { ProductCard } from "matters/ProductCard";
import * as Sentry from "@sentry/react";
import SearchResultHeader from "./SearchResultHeader";
import { SearchProductBase } from "@patentedai/common/entities/SearchProduct";
import { SearchProductRelatedPatentBaseExtended } from "@patentedai/common/entities/SearchProductRelatedPatent";

const StyledTab = styled(Tab)(() => ({
    textTransform: "none",
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 600,
    letterSpacing: "-0.01em",
    borderWidth: "1px 1px 0px 1px",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    minHeight: "50px",
    "&.Mui-selected": {
        color: "black",
        background: "white",
        backdropFilter: "blur(50px)",
        borderRadius: "10px 10px 0px 0px",
    },
    "&:not(.Mui-selected)": {
        background: "rgba(255, 255, 255, 0.45)",
        borderRadius: "10px 10px 0px 0px",
        opacity: 0.9,
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent", // Remove the indicator line
    },
    "& .MuiTab-iconWrapper": {
        marginRight: "12px", // Remove the indicator line
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            className="tab-panel"
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

interface PriorArtDetailProps {
    initialSelectedPriorArtId?: string;
};

const SearchResultDetail = ({ initialSelectedPriorArtId }: PriorArtDetailProps) => {
    const { search, priorArts, getNextPriorArtPage, patent } = useSearch();
    const { workspace } = useAuth();
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState<SearchProductBase | undefined>(undefined);
    const [isLoadingRelatedPatents, setIsLoadingRelatedPatents] = useState<boolean>(false);
    const [isLoadingMatter, setIsLoadingMatter] = useState<boolean>(false);
    const [relatedPatents, setRelatedPatents] = useState<SearchProductRelatedPatentBaseExtended[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(true);
    const [isPatentView, setIsPatentView] = useState(false);
    const [patentCompared, setPatentCompared] = useState<SearchProductRelatedPatentBaseExtended>();
    const [diagramImage, setDiagramImage] = useState<Blob | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    priorArts.sort(sortProductsByDateAndName);

    const encoded = plantumlEncoder.encode(extractUMLCode(isPatentView ? patentCompared?.claimChartReasons[0].overlapDiagram || "" : selectedProduct?.claimChartReasons[0].scenarioDiagram || ""));

    useEffect(() => {
        const fetchDiagram = async () => {
            if (encoded.length < 10) setIsImageLoaded(false);
            else {
                setDiagramImage(null);
                try {
                    const response = await fetch(`https://plantuml-server-d4ifaf7eya-uc.a.run.app/img/${encoded}`);
                    if (!response.ok) {
                        setIsImageLoaded(false);
                        return;
                    }
                    const imageBlob = await response.blob();
                    setDiagramImage(imageBlob);
                    setIsImageLoaded(true);
                }
                catch {
                    setIsImageLoaded(false);
                }
            }
        };
        fetchDiagram();
    }, [selectedProduct, encoded]);

    useEffect(() => {
        const getPriorArts = async () => {
            if (priorArts.length)
                return;
            await getNextPriorArtPage();
        };

        getPriorArts();

        // HACK: we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!initialSelectedPriorArtId)
            setSelectedProduct(priorArts[0]);
        else {
            const priorArt = priorArts.find(priorArt => priorArt.externalId === initialSelectedPriorArtId);
            if (!priorArt)
                return;

            setSelectedProduct(priorArt);
        }
        // HACK: we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priorArts]);

    useEffect(() => {
        const callGetRelatedPatents = async () => {
            try {
                if (!selectedProduct || !patent || !workspace || !search)
                    return;

                setIsLoadingRelatedPatents(true);
                // TODO: implement
                const response = await getSearchProductRelatedPatents({
                    workspaceId: workspace.id,
                    searchId: search.id,
                    productId: selectedProduct.id,
                    pageSize: 50,
                });
                if (response.status !== ResponseStatus.Success)
                    throw new Error("Error getting Related Patents");

                setRelatedPatents(response.relatedPatents);
            } catch (error) {
                console.log(error);
                setRelatedPatents([]);
                Sentry.captureException(error);
            }
            finally {
                setIsLoadingRelatedPatents(false);
            }
        };

        callGetRelatedPatents();
    }, [selectedProduct, patent, search, workspace]);

    const handleSelectPriorArt = (priorArt: SearchProductBase) => {
        if (!priorArt)
            return;
        setSelectedProduct(priorArt);
    };

    const onTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };
    const handleImageClick = () => {
        // Open the image in a new tab
        window.open(`https://www.plantuml.com/plantuml/img/${encoded}`, "_blank");
    };

    const handleOpenMatterModal = () => {
        if (!search)
            return;
        setIsModalOpen(true);
    };
    const handleCreateNewMatter = async (name: string, description: string) => {
        if (!workspace || !search || !selectedProduct)
            return;

        setIsLoadingMatter(true);
        const response = await createMatter({
            name,
            description,
            workspacesId: workspace?.id,
            priorArtId: selectedProduct.externalId,
            patent: {
                publicationNumber: search.targetPatent.publicationNumber,
                title: search.targetPatent.title,
                selectedClaims: search.targetPatent.selectedClaims,
            },
            search: {
                id: search.id,
                searchNumber: search.searchNumber,
            }
        });

        if (response.status !== ResponseStatus.Success)
            throw new Error("Error creating Matter");

        setIsLoadingMatter(false);
        navigate(`/matter/${response.matter.id}`,
            {
                state: {
                    patent,
                    search,
                    matter: response.matter,
                    matterProduct: response.matterProduct
                }
            });
    };
    const handleAddToMatter = () => {
        //TODO: implement
    };


    if (!search) {
        return (
            <div>
                Search not found!
            </div>
        );
    }
    if (!patent) {
        return (
            <div>
                Patent not found!
            </div>
        );
    }
    if (!selectedProduct || !priorArts) {
        return (
            <Backdrop open={true}>
                <Loading />
            </Backdrop>
        );
    }

    const togglePatentView = (patent?: SearchProductRelatedPatentBaseExtended) => {
        if (patent) {
            setPatentCompared(patent);
            setIsPatentView(true);
        }
        else {
            setPatentCompared(undefined);
            setIsPatentView(false);
        }
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <>
            <div className="prior-art-list-container">
                <div className="content prior-art-details-content">
                    <div className="menu">
                        <PriorArtDetailMenu
                            patent={patent}
                            searchNumber={search.searchNumber}
                            priorArts={priorArts}
                            selectedPriorArtId={selectedProduct?.externalId || ""}
                            onSelectPriorArt={handleSelectPriorArt}
                            onSelectRelatedPatent={() => { }}
                            toggleToView={() => { }}
                            activeView="PRODUCT"
                        />
                    </div>
                    <div ref={scrollContainerRef} className="detail">
                        <div className="info-container">
                            <div className="info-header">
                                <SearchResultHeader
                                    priorArt={selectedProduct}
                                    returnToPriorArtView={() => { }}
                                    patent={patentCompared}
                                    activeView="PRODUCT"
                                />
                                <UserHeader />
                            </div>
                            <div className="stretch-row">
                                {!isPatentView ?
                                    <div className="quick-info-row">
                                        <div className="info">
                                            <BriefcaseIcon color="#FB0F42" />{selectedProduct?.companyName}
                                        </div>
                                        {selectedProduct?.stringedReleaseDate &&
                                            <div className="info">
                                                <CalendarIcon color="#FB0F42" /> {selectedProduct.stringedReleaseDate}
                                            </div>
                                        }
                                        <div className="info">
                                            <IndustriesTag color="#FB0F42" /> {selectedProduct?.productIndustry}
                                        </div>
                                    </div>
                                    :
                                    <div className="stretch-row">
                                        <div className="patent-name">
                                            {patentCompared?.priorArtPatent.title}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="stretch-row">
                                <div className="reason styled-scroll">
                                    {isPatentView ? patentCompared?.priorArtPatentObject.abstract : filterText(selectedProduct.reason)}
                                </div>
                                <div className="add-to-matter-row">
                                    <div className="quick-info-row">
                                        {isPatentView &&
                                            <>
                                                {patentCompared?.priorArtPatentObject.assignee &&
                                                    <div className="info">
                                                        <BriefcaseIcon color="#FB0F42" />{patentCompared?.priorArtPatentObject.assignee}
                                                    </div>
                                                }
                                                {patentCompared?.priorArtPatentObject.priorityDate &&
                                                    <div className="info">
                                                        <CalendarIcon color="#FB0F42" /> {DateTime.fromFormat(patentCompared.priorArtPatentObject.priorityDate, "yyyyMMdd").toFormat("yyyy, MMM dd")}
                                                    </div>
                                                }
                                                {patentCompared?.priorArtPatentObject?.relatedIndustries?.length !== 0 &&
                                                    <div className="info">
                                                        <IndustriesTag color="#FB0F42" /> {patentCompared?.priorArtPatentObject.relatedIndustries}
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <Button
                                        className="add-to-matter-button"
                                        variant="contained"
                                        onClick={handleOpenMatterModal}
                                    >
                                        + Add to Matter
                                    </Button>
                                    <AddToMatterModal
                                        isLoading={isLoadingMatter}
                                        handleClose={() => setIsModalOpen(false)}
                                        open={isModalOpen}
                                        priorArt={selectedProduct}
                                        handleAddToMatter={handleAddToMatter}
                                        handleCreateNewMatter={handleCreateNewMatter}
                                    />
                                </div>
                            </div>
                        </div>
                        <UseCaseCard
                            claimChartReason={(isPatentView ? patentCompared : selectedProduct)?.claimChartReasons[0]}
                            diagramImage={diagramImage}
                            handleImageClick={handleImageClick}
                            isImageLoaded={isImageLoaded}
                            publicationNumber={patent.publicationNumber}
                        />
                        <div className="tabs-container">
                            {isPatentView ?
                                <>
                                    <div className="patent-v-patent-header">
                                        <div className="pvp-wrapper prior-art-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {patent.publicationNumber}
                                            </div>
                                        </div>
                                        <div className="vs">VS</div>
                                        <div className="pvp-wrapper related-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {patentCompared?.priorArtPatentObject.publicationNumber}
                                            </div>
                                        </div>
                                    </div>
                                    <ClaimOverlapTable
                                        claimChartReasons={patentCompared?.claimChartReasons}
                                        patent={patent}
                                        showPvPHeader
                                    />
                                </>
                                :
                                <>
                                    <div className="patent-v-patent-header">
                                        <div className="pvp-wrapper prior-art-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {patent.publicationNumber}
                                            </div>
                                        </div>
                                        <div className="vs">VS</div>
                                        <div className="pvp-wrapper related-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {selectedProduct.productName}
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs
                                        variant="scrollable"
                                        className="tabs-row"
                                        TabIndicatorProps={{ sx: { display: "none" } }}
                                        value={selectedTab}
                                        onChange={onTabChange}
                                    >
                                        <StyledTab icon={<DottedDoc />} iconPosition="start" label="Claim Overlap" />
                                        {(relatedPatents.length > 0
                                            && checkSubstringInEither(selectedProduct?.companyName?.toLowerCase(), relatedPatents[0]?.priorArtPatentObject.assignee.toLowerCase())) &&
                                            <StyledTab icon={<PatentIcon />} iconPosition="start" label="Related Patents" />
                                        }
                                    </Tabs>
                                    <TabPanel value={selectedTab} index={0}>
                                        <ClaimOverlapTable
                                            claimChartReasons={selectedProduct.claimChartReasons}
                                            patent={patent}
                                        />
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={1}>
                                        <div className="section-container">
                                            {isLoadingRelatedPatents ?
                                                <Loading />
                                                :
                                                <>
                                                    {relatedPatents.map((relatedPatent, rowIdx) => (
                                                        <ProductCard
                                                            patent={relatedPatent}
                                                            patentSearched={patent}
                                                            key={rowIdx}
                                                            onClick={togglePatentView}
                                                        />
                                                    ))
                                                    }
                                                </>
                                            }
                                        </div>
                                    </TabPanel>
                                </>
                            }
                        </div>
                    </div >
                </div >
            </div >
        </>

    );
};

export default SearchResultDetail;

import { workspaceNameSanitizer } from "@patentedai/common/helpers/sanitizerHelper";

interface UserData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string,
    status: UserStatus;
    role: UserRole;
    createdAt: Date;
    updatedAt?: Date;
    defaultWorkspaceId?: string;
    mailChimpId?: string;
}

interface UserBase extends UserData {
    id: string
}

class UserBase {
    constructor(id: string, data: UserData) {
        this.id = id;
        Object.assign(this, data);
    }
    fullname(): string {
        return this.firstName + " " + this.lastName;
    }
    isAdmin(): boolean {
        return this.role === UserRole.Admin;
    }
    defaultWorkspaceName(): string {
        return workspaceNameSanitizer(this.fullname());
    }
    toData(): UserData {
        const { id, ...data } = this;
        return data;
    }
}

enum UserStatus {
    Active = "active",
    Inactive = "inactive",
    Deleted = "deleted"
}

enum UserRole {
    Admin = "admin",
    User = "user"
}

export { UserBase, UserStatus, UserRole };
export type { UserData };
import { PatentData } from "./Patent";

interface GenerationProcess {
    id: string;
    status: ProcessStatus;
}

interface RelatedPatentsProcess {
    status: ProcessStatus;
    pageSize: number;
    lastProductIdImported?: string;
}

interface OfflineSearchData {
    searchNumber: number;
    targetPatent: PatentData;
    selectedClaims: number[];
    productsProcess: GenerationProcess;
    patentsProcess: GenerationProcess;
    relatedPatentsProcess: RelatedPatentsProcess;
    status: OfflineSearchStatus;
    updateStatusTaskId?: string;
    dataIngestTaskId?: string;
    createdAt: Date;
    updatedAt?: Date;
}

interface OfflineSearchBase extends OfflineSearchData {
    id: string;
}

class OfflineSearchBase {
    constructor(id: string, data: OfflineSearchData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): OfflineSearchData {
        const { id, ...data } = this;
        return data;
    }
}

enum ProcessStatus {
    NotRequested = "not_requested",
    Queued = "queued",
    Processing = "processing",
    ProcessingChildren = "processing_children",
    ProcessingFinished = "processing_finished",
    Completed = "completed",
    Failed = "failed"
}

enum OfflineSearchStatus {
    Queued = "queued",
    Processing = "processing",
    GettingResults = "getting_results",
    RunningScripts = "running_scripts",
    Completed = "completed",
    Failed = "failed"
}

export { OfflineSearchBase, ProcessStatus, OfflineSearchStatus };
export type { OfflineSearchData, GenerationProcess, RelatedPatentsProcess };
import { styled } from "@mui/material/styles";
import { MenuWrapper } from "menu/MenuWrapper";
import PriorArtCard from "./PriorArtCard";
import { ReactComponent as TuneIcon } from "@patentedai/web/images/tuneIcon.svg";
import PatentMenuCard from "./PatentMenuCard";
import Divider from "@patentedai/web/components/core/Divider";
import Button from "@patentedai/web/components/core/Button";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useSearch } from "contexts/SearchContext";
import { Loading } from "@patentedai/web/components/core/Loading";
import { useNavigate } from "react-router-dom";
import { SearchProductBase } from "@patentedai/common/entities/SearchProduct";
import { OfflineSearchProduct } from "@patentedai/web/entities/OfflineSearchProduct";
import SidebarResultTabs from "./components/SidebarResultTabs";
import { OfflineSearchPatent } from "@patentedai/web/entities/OfflineSearchPatent";
import { SearchResultsViewMode } from "offlineSearch/OfflineSearchResultDetail";

const removeDuplicatesAndKeepMostRecent = (products: SearchProductBase[] | OfflineSearchProduct[]) => {
    const uniqueProducts: { [key: string]: SearchProductBase | OfflineSearchProduct } = {};

    for (const product of products) {
        if (!uniqueProducts[product.productName.toLowerCase()] || product?.generatedAt > uniqueProducts[product.productName]?.generatedAt) {
            uniqueProducts[product.productName.toLowerCase()] = product;
        }
    }

    return Object.values(uniqueProducts);
};

interface PriorArtDetailMenuProps {
    patent: ReducedPatentData;
    priorArts: SearchProductBase[] | OfflineSearchProduct[];
    relatedPatents?: OfflineSearchPatent[];
    selectedRelatedPatentId?: string
    selectedPriorArtId?: string;
    onSelectRelatedPatent: (relatedPatent: OfflineSearchPatent) => void;
    onSelectPriorArt: (priorArt: SearchProductBase) => void;
    toggleToView: (selectedTab: SearchResultsViewMode) => void;
    searchNumber: number;
    blockNavigation?: boolean;
    activeView: SearchResultsViewMode;
}

const PriorArtDetailMenu = ({ patent, priorArts, relatedPatents, selectedRelatedPatentId, onSelectRelatedPatent, selectedPriorArtId, searchNumber, onSelectPriorArt, blockNavigation = false, toggleToView, activeView }: PriorArtDetailMenuProps) => {
    const navigate = useNavigate();
    const { getNextPriorArtPage, isLoadingPriorArts, search } = useSearch();

    const filteredPriorArts = removeDuplicatesAndKeepMostRecent(priorArts);

    const handleSelectTab = (selectedTab: SearchResultsViewMode) => {
      toggleToView(selectedTab);
    };

    const onScrollToBottom = async () => {
        if (isLoadingPriorArts)
            return;
        await getNextPriorArtPage();
    };
    const scrollRef = useBottomScrollListener(onScrollToBottom) as React.LegacyRef<HTMLDivElement>;

    const handleSelectPriorArt = (priorArt: SearchProductBase) => {
        if (!priorArt)
            return;
        onSelectPriorArt(priorArt);
    };

    const handleNewPriorArtSearchClick = () => {
        navigate("/patent");
    };

    const goToViewSearchCriteria = () => {
        if (!search?.id) return;
        navigate(`/search/${search?.id}/criteria`);
    };

    return (
        <MenuWrapper>
            <DetailMenuWrapper style={{ margin: `${blockNavigation ? "20px 0px 12px" : "20px 0px 62px"}` }}>
                <div className="menu-title">
                    <h2>Prior Art Search #{searchNumber}</h2>
                    {!blockNavigation && (
                        <Button
                            variant="outlined"
                            className="filtering-button"
                            onClick={goToViewSearchCriteria}
                        >
                            <TuneIcon />
                            View Search Criteria
                        </Button>
                    )}
                </div>
                <Divider />
                <PatentMenuCard patent={patent} />
                <Divider />
                <SidebarResultTabs
                handleSelectTab={handleSelectTab}
                selectedTab={activeView}
                />
                    <div
                        className="results styled-scroll"
                        ref={scrollRef}>
                        {activeView === "PRODUCT" && filteredPriorArts.length > 0 && (
                          <>
                                {
                                    filteredPriorArts.filter((priorArt) => priorArt.externalId === selectedPriorArtId)
                                        .map((priorArt, index) => (
                                            <PriorArtCard
                                                handleSelectPriorArt={handleSelectPriorArt}
                                                selectedPriorArtId={selectedPriorArtId}
                                                key={index}
                                                patent={patent}
                                                priorArt={priorArt}
                                            />
                                        ))
                                }
                                <div className="results-divider">
                                    Other results
                                    <div className="line"></div>
                                </div>
                                {
                                    filteredPriorArts.map((priorArt, index) =>
                                    (priorArt.externalId !== selectedPriorArtId &&
                                        <PriorArtCard
                                            handleSelectPriorArt={handleSelectPriorArt}
                                            selectedPriorArtId={selectedPriorArtId}
                                            key={index}
                                            patent={patent}
                                            priorArt={priorArt}
                                        />
                                    ))
                                }
                                {
                                    isLoadingPriorArts && <Loading />
                                }
                          </>
                        )}
                        {activeView === "PATENT" && relatedPatents?.length && (
                          <>
                            {
                              relatedPatents.filter((relatedPatent) => relatedPatent.id === selectedRelatedPatentId)
                                  .map((relatedPatent, index) => (
                                      <PriorArtCard
                                        handleSelectRelatedPatent={onSelectRelatedPatent}
                                        selectedRelatedPatentId={selectedRelatedPatentId}
                                        key={index}
                                        patent={patent}
                                        relatedPatent={relatedPatent}
                                      />
                                  ))
                            }
                            <div className="results-divider">
                                Other results
                                <div className="line"></div>
                            </div>
                            {relatedPatents.map((relatedPatent, index) => (
                              (relatedPatent.id !== selectedRelatedPatentId && (
                                <PriorArtCard
                                  handleSelectRelatedPatent={onSelectRelatedPatent}
                                  selectedRelatedPatentId={selectedRelatedPatentId}
                                  key={index}
                                  patent={patent}
                                  relatedPatent={relatedPatent}
                                />
                              ))
                            ))}
                          </>
                        )}
                    </div>
            </DetailMenuWrapper>
            {!blockNavigation && (
                <ButtonWrapper>
                    <Button
                        variant="contained"
                        className="new-search-button"
                        onClick={handleNewPriorArtSearchClick}
                    >
                        New Prior Art Search
                    </Button>
                </ButtonWrapper>
            )}
        </MenuWrapper>
    );
};

const DetailMenuWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 20px 0px 62px;
    overflow: hidden;
    position: relative;
    .menu-title{
        display: flex;
        flex-direction: column;
        gap: 15px;
        h2{
            color: var(--color-palette-grey-100, #1F2023);
            font-family: Manrope;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: -0.3px;
            margin: 0;
        }
        .filtering-button{
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            gap:8px;
            padding: 5px 12px;
            border-color: var(--color-palette-red-dark, #DF1440);
            color: var(--color-palette-red-dark, #DF1440);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: -0.12px;
        }
    }
    .results{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        overflow: auto;
        .results-divider{
            display: flex;
            align-items: center;
            width: 100%;
            gap: 9px;
            padding: 12px 0px;
            color: var(--color-palette-grey-100, #1F2023);
            font-family: Manrope;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 13px;
            letter-spacing: 1px;
            text-transform: uppercase;
            .line{
                flex-grow: 1;
                border-top: 1px solid #DDDEDE;
            }
        }
    }
    .button-wrapper{
        display: flex;
        width: 400px;
        height: 184px;
        padding: 20px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        position: absolute;
        bottom: 0px;
        border-radius: 0px 0px 20px 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        box-sizing: border-box;
    }
    .new-search-button{
        display: flex;
        height: 50px;
        padding: 12px 27px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--color-palette-red-dark, #DF1440);
        background: var(--color-palette-red-dark, #DF1440);
        box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 0px 4px 9px 0px rgba(195, 19, 57, 0.21), 0px 17px 17px 0px rgba(195, 19, 57, 0.18), 0px 38px 23px 0px rgba(195, 19, 57, 0.11), 0px 67px 27px 0px rgba(195, 19, 57, 0.03), 0px 105px 29px 0px rgba(195, 19, 57, 0.00);
    }
`;
const ButtonWrapper = styled("div")`
        pointer-events: none;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 184px;
        padding: 20px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        border-radius: 0px 0px 20px 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    .new-search-button{
        pointer-events: all;
        display: flex;
        height: 50px;
        padding: 12px 27px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--color-palette-red-dark, #DF1440);
        background: var(--color-palette-red-dark, #DF1440);
        box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 0px 4px 9px 0px rgba(195, 19, 57, 0.21), 0px 17px 17px 0px rgba(195, 19, 57, 0.18), 0px 38px 23px 0px rgba(195, 19, 57, 0.11), 0px 67px 27px 0px rgba(195, 19, 57, 0.03), 0px 105px 29px 0px rgba(195, 19, 57, 0.00);
    }
`;

export default PriorArtDetailMenu;

